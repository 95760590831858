@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply p-4;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
    /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/
  }
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #00cba9;
    cursor: pointer;
    /*background: red;*/
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: gray;
    /*outline: 1px solid slategrey;*/
  }
}
