.react-calendar__month-view__days__day--weekend {
    color: #000000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575
}

.marked {
    background-color: rgb(110, 33, 67);
    color: white;
}

.react-calendar__tile--active {
    background: #006edc;
    color: white;
}

.react-calendar {
    width: 350px;
}